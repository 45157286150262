import { OneOneColor } from '@pafcloud/style';

export const Colors = {
  Text: OneOneColor.Light,
  TextHover: OneOneColor.White,
  TextActive: OneOneColor.White,
  TextShadow: undefined,
  TextTransform: undefined,

  Icon: OneOneColor.Neutral,
  IconActive: 'currentColor',

  Background: undefined,
  BackgroundHover: undefined,
  BackgroundActive: undefined,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: OneOneColor.Darker,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: 'uppercase',
  Weight: 800,
  WeightHover: 800,
  WeightActive: 900,
} as const;
