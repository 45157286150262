import { OneOneColor } from '@pafcloud/style';

export const Colors = {
  Text: OneOneColor.White,
  MessageSeenText: OneOneColor.Neutral,
  PaginationText: OneOneColor.BodyText,

  BorderColor: OneOneColor.DividerLight,

  Highlight: OneOneColor.BlackTransparent50,
  HighlightBorder: 'transparent',
};
