import { OneOneColor } from '@pafcloud/style';

export const Colors = {
  Text: OneOneColor.White,
  DepositQuickButtons: undefined,
  DepositQuickButtonsHover: undefined,
  Border: undefined,
  Background: `
  linear-gradient(0deg, ${OneOneColor.Black}, ${OneOneColor.Darker} ),
  linear-gradient(0deg, ${OneOneColor.Primary}, ${OneOneColor.Black} 50%, ${OneOneColor.Black} )`,
  SidebarContent: undefined,
  SidebarTopBackground: undefined,
  SidebarOverlay: `${OneOneColor.Black}b0`,
  EmphasizedLink: undefined,
};
