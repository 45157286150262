import type { FC } from 'react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import dynamic from 'next/dynamic';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint, deviceWithBackdropFilter, OneOneColor } from '@pafcloud/style';
import { ButtonLink, Link, Logo } from '@pafcloud/base-components';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { MessageMenu } from '../menus/message-menu';
import { HeaderColors } from '../header-colors';
import { BurgerComponent } from '../BurgerComponent';
import type { HeaderContentOneOne_content$key } from './__generated__/HeaderContentOneOne_content.graphql';
import { StyledHeader } from './shared';
import type { HeaderContentProps } from './shared';
import { HeaderMenu } from './HeaderMenu';
import { HeaderSearchBar } from './HeaderSearchBar';

const ProfileMenu = dynamic(() => import('../menus/profile-menu/ProfileMenu'), { ssr: false });

const contentFragment = graphql`
  fragment HeaderContentOneOne_content on Query {
    ...HeaderMenu_content
    ...HeaderSearchBar_data
    ...ProfileMenu_data
  }
`;

const Header = styled(StyledHeader)({
  position: 'fixed',
  justifyItems: 'flex-start',
  background: HeaderColors.Background,
  boxShadow: 'unset',
  overflow: 'hidden',
  paddingRight: 'var(--full-width-margin)',
  paddingLeft: 'var(--full-width-margin)',
  borderBottom: `1px solid ${OneOneColor.DividerLight}`,

  [deviceWithBackdropFilter]: {
    background: HeaderColors.BackgroundBlur,
    backdropFilter: 'blur(10px) saturate(150%)',
  },

  // Move the header when main menu (sidebar) is open.
  transition: 'transform 0.35s 0.0s',
  '--menu-open-animation-offset': 'min(100px, 50%)',

  '&:has(~ nav[data-open="true"])': {
    transform: 'translateX(var(--menu-open-animation-offset))',
  },

  [Breakpoint.LaptopOrLarger]: {
    '--menu-open-animation-offset': '100px',
  },
});

const HeaderNavigation = styled(HeaderMenu)({
  display: 'none',
  height: '100%',
  margin: 0,

  [Breakpoint.LaptopOrLarger]: {
    display: 'grid',
  },
});

const ButtonSection = styled.div({
  display: 'grid',
  gridAutoFlow: 'column',
  marginLeft: 'auto',
});

const LogoLink = styled(Link)({
  display: 'flex',
  position: 'relative',
  outline: 'none',
  marginRight: 32,
  padding: 3, // Magic number to match the height of the burger outline.

  ':focus-visible': {
    outline: `1px solid ${OneOneColor.Light}`,
    outlineOffset: 2,
    borderRadius: 2,
  },
});

const MobileLogo = styled(Logo)({
  maxWidth: '100%',
  height: 28,

  [Breakpoint.LaptopOrLarger]: {
    display: 'none',
  },
});

const DesktopLogo = styled(Logo)({
  height: 28,
  display: 'none',

  [Breakpoint.LaptopOrLarger]: {
    display: 'block',
  },
});

const BurgerButton = styled.button({
  marginRight: 8,
  padding: '8px 6px',
  background: 'none',
  border: 'none',
  color: OneOneColor.White,
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',

  outline: 'transparent',

  ':focus-visible': {
    outline: `1px solid ${OneOneColor.Light}`,
    outlineOffset: 2,
    borderRadius: 2,
  },
});

const HeaderButtonLink = styled(ButtonLink)({
  alignSelf: 'center',
  minWidth: 92,
});

const HeaderLogo = () => {
  return (
    <>
      <MobileLogo />
      <DesktopLogo />
    </>
  );
};

export const HeaderContent: FC<HeaderContentProps> = ({ isMainMenuOpen, setMainMenuOpen, ...props }) => {
  const content = useFragment<HeaderContentOneOne_content$key>(contentFragment, props.content);
  const { t } = useTranslation(['header', 'profile']);
  const { getFlowUrl } = useFlowRouter();
  const isLoggedIn = useIsLoggedIn();

  const menuAriaLabel = isMainMenuOpen ? t('main-menu-button.close.aria-label') : t('main-menu-button.open.aria-label');

  return (
    <Header>
      <BurgerButton
        onClick={() => setMainMenuOpen(!isMainMenuOpen)}
        aria-label={menuAriaLabel}
        aria-expanded={isMainMenuOpen}
        data-name="main-menu-button"
      >
        <BurgerComponent open={isMainMenuOpen} />
      </BurgerButton>

      <LogoLink aria-label={t('logo.aria-label')} href="/">
        <HeaderLogo />
      </LogoLink>

      <HeaderNavigation content={content} />

      <ButtonSection>
        <HeaderSearchBar data={content} />
        {isLoggedIn && (
          <>
            <MessageMenu />
            <ProfileMenu data={content} />
          </>
        )}
        {!isLoggedIn && (
          <HeaderButtonLink
            href={getFlowUrl('login')}
            aria-label={t('header-button.logged-out.aria-label')}
            size="default"
            variant="primary"
            type="button"
            data-testid="header-action"
            colorstyle="accent"
          >
            {t('header-button.logged-out.text')}
          </HeaderButtonLink>
        )}
      </ButtonSection>
    </Header>
  );
};
